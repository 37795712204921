<template>
  <el-main>
    <el-row>
      <el-col :span="24">
        <el-button type="primary" @click="addCode">生成邀请码</el-button>
      </el-col>
    </el-row>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          prop="code"
          label="邀请码"
      >
      </el-table-column>
      <el-table-column
          prop="is_used_cn"
          label="状态"
      >
      </el-table-column>
      <el-table-column
          prop="insert_time"
          label="生成时间"
      >
      </el-table-column>
      <el-table-column
          label="操作" align="right">
        <template slot-scope="scope">
          <el-button v-if="scope.row.is_used==0" type="primary" size="mini" @click="deleteCode(scope)">邀请码失效</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-main>
</template>

<script>
export default {
  name: "InviteCode",
  created() {
    this.listCode();
  },
  data() {
    return {
      rawData: []
    }
  },
  computed: {
    tableData() {
      let me = this;
      if (!me.rawData) {
        return [];
      }
      return me.rawData.map(val=>{
        val['is_used_cn'] = val['is_used'] ? '已使用' : '未使用';
        return val;
      });
    }
  },
  methods: {
    listCode() {
      let me = this;
      me.axios.get("/api/code/list").then(res => {
        me.rawData = res;
      })
    },
    deleteCode(scope) {
      let me = this;
      me.axios.post('/api/code/delete', {id: scope.row.id}).then(() => {
        me.listCode();
      })
    },
    addCode(){
      let me = this;
      me.axios.post('/api/code/add').then(() => {
        me.listCode();
      })
    }
  }
}
</script>

<style scoped>

</style>